
import Vue from "vue"
export default Vue.extend({
	props: {
		timestamp: {
			type: String,
            required: true
		},
		responseStatus: {
			type: String,   // A Message to be displayed with the alert.
		},
        responseType: {
            type: String  // May be: success | error | info
        }
    },
})


